.dt-info-icon {
  font-size: 18px;
}

th.dt-search-header {
  padding-top: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 10px !important;
}

th.dt-search-header input {
  padding-left: 5px !important;
}

.btn-dt-main {
  border: 0px;
}

.btn-dt-filter {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 2px;
  border-bottom-color: #0abfbc;
  border-bottom-style: solid;
  width: 90%;
}

.table th, .table td {
  border-bottom: 1px solid #e3ebf3 !important;
  border-top: 0px;
}

div.dt-buttons {
  clear: both;
  float: right;
}

.dt-buttons .btn {
  background-color: transparent;
  border-color: transparent;
  color: grey;
  font-size: 1.5rem;
  padding: 5px;
}

.dt-buttons .btn .la {
    font-size: 1.9rem;
}

.dt-buttons .btn:hover {
  color: #464855;
}

.dt-buttons .btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: grey !important;
  font-size: 1.5rem;
  padding: 5px;
}

.status-green {
  width: 25px;
  padding-left: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid #28D094 !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-red {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid #FF4961 !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-orange {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid #f77a1c !important;/*rgb(240, 166, 71);*/
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-yellow {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid rgb(241, 234, 127) !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-grey {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid rgba(212, 210, 210, 0.781) !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.round-icon {
  line-height: initial;
  padding: 0.78rem 1.1rem;
  font-size: 24px;
  position: relative;
  top: -45px;
  margin-left: 10px;
}

.dt-icons {
  height: 30px;
}

.dt-user-avatar {
  border-radius: 50%;
  background-color: #474789;
  color: #fff;
  line-height: 40px;
  width:40px;
  height:40px;
  text-align: center;
}

.paleta-preventivo{
  background-color: #4dc4bc;
  color: #fff !important;
}

.paleta-correctivo{
  background-color: #67b7dc;
  color: #fff !important;
}

.paleta-vencimiento{
  background-color: #fe9657;
  color: #fff !important;
}

.paleta-en-taller{
  background-color: #7a7ae5;
  color: #fff !important;
}

.paleta-gestoria{
  background-color: #f7c361;
  color: #fff !important;
}

.paleta-combustible{
  background-color: #dc6967;
  color: #fff !important;
}

.paleta-infracciones{
  background-color: #616c6f;
  color: #fff !important;
}

.dt-user-avatar-image {
  border-radius: 50%;
  color: #fff;
  line-height: 40px;
  width:40px;
  height:40px;
  text-align: center;
  background-size: auto 40px;
  background-repeat: no-repeat;
  background-position: center;
}

#dataTable td {
  line-height: 40px;
}

#dataTable th {
  font-weight: 400;
}

.btn-dt-grid {
  border: 0;
  background-color: transparent !important;
}

.btn-dt-grid i {
  font-size: 1.3rem !important;
}

.btn-dt-grid .ft {
  font-size: 1.5rem !important;
}

.dt-info-icon {
  font-size: 1.4rem;
}

.dt-info-icon .la {
  font-size: 1.4em;
}

.table-hover tbody tr:hover {
  background-color: rgba(248, 240, 255, 0.5);
}

.dataTables_processing {
  z-index: 1000 !important;
}

.dataTable tbody input {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 2px !important;
  border-bottom-color: #0abfbc !important;
  border-bottom-style: solid !important;
  text-align: right !important;
}


.table th, .table td {
  padding: 0.75rem 1rem !important;
}

.btn-icon .fa-2x {
  font-size: 1.7em !important;
}

/* Fix Datatables con scroll */
div.dataTables_scrollBody > table.dataTable > thead > tr {
  display: none;
}
/* Fix Datatables con scroll */

.dataTableMovil td {
  vertical-align: middle;
}

.dropright .dropdown-toggle{
  text-align: left;
  background-color: transparent;
  border: none;
}

.dropright .dropdown-toggle i{
  color: #6B6F82;
  font-size: 16px;
}

.dropright .dropdown-toggle::after {
  border: none;
  display:none;
}

.dropright .dropdown-menu .dropdown-header{
  color: #ffffff;
  background-color: #241b3e;
}

.dropright .dropdown-menu .dropdown-item:hover{
  background-color: rgb(169, 169, 169);;
}

.p-0.embudo{
  padding: 0.75rem 0rem !important;
}

.button-embudo{
  padding-left: 0rem;
  cursor: pointer;
}

#dataTable td.center-column{
  text-align: center;
}

#dataTable td.custom-lineHeight {
  line-height: 15px;
}

/* CHECK THIS WITH TEAM */

/* #collection-import ~ div{
  left: -10px !important;
} */

#dataTable td.custom-padding-cero {
  padding: 0 !important;
}

#dataTable td.custom-td-llanta-impeccion-detalles-accion {
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

#dataTable td.custom-td-llanta-impeccion-detalles-puntos-medicion {
  padding: 0 !important;
  border: 2px solid #1a1036 !important;
}

#dataTable td.custom-td-llanta-impeccion-detalles {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 2px solid #1a1036 !important;
}

#dataTable td.custom-td-llanta-asignacion-detalles {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 2px solid #1a1036 !important;
}

.datatablesnew-status-icon-fa-fas-circle{
  font-size: 11px !important;
  position: relative;
  top: -6px;
}

.datatables-status-icon-fa-fas-circle{
  font-size: 11px !important;
  position: relative;
  top: 9px;
}

.dt-input-holder {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 2px !important;
  border-bottom-color: #0abfbc !important;
  border-bottom-style: solid !important;
  text-align: right !important;
}

/* Chrome, Safari, Edge, Opera */
.input-no-arrows input::-webkit-outer-spin-button,.input-no-arrows input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-no-arrows input{
  -moz-appearance:textfield;
}

.proximasTareas {
  height: 30px;
}

.pointer {
  cursor: pointer;
}